import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

export const amountFormat = (value, formattor) => {
	let retValue = value;
	if (value !== null && value !== undefined) {
		let decimal = formattor.decimalPoint != null || formattor.decimalPoint !== undefined ? formattor.decimalPoint : 2;
		if (formattor.format === "round") {
			retValue = Math.round(Number(value));
		} else {
			retValue = Number(value).toFixed(decimal);
		}
		retValue = Intl.NumberFormat("en-US", {
			minimumFractionDigits: decimal,
		}).format(retValue);
		if (formattor.displaySign) {
			retValue = "$" + retValue;
		}
	}
	return retValue;
};

export const percentageFormat = (value, formattor) => {
	let retValue = value;
	if (value !== null && value !== undefined) {
		let decimal = formattor.decimalPoint != null || formattor.decimalPoint != undefined ? formattor.decimalPoint : 2;
		if (formattor.format == "round") {
			retValue = Math.round(Number(value));
		} else {
			retValue = Number(value).toFixed(decimal);
		}
		retValue = Intl.NumberFormat("en-US", {
			minimumFractionDigits: decimal,
		}).format(retValue);
		if (formattor.displaySign) {
			retValue = retValue + "%";
		}
	}
	return retValue;
};

export const booleanFormat = (value, formattor) => {
	let retValue = value;

	if (value !== null && value !== undefined) {
		if (value) {
			retValue = (
				<FontAwesomeIcon
					icon={formattor.iconIfTrue ?? "check"}
					size={formattor.size ?? "1x"}
					style={{ color: formattor.colorIfTrue ?? "#22d628" }}
				/>
			);
		} else if (formattor.iconIfFalse) {
			retValue = (
				<FontAwesomeIcon
					icon={formattor.iconIfFalse}
					size={formattor.size ?? "1x"}
					style={{ color: formattor.colorIfFalse ?? "#FF8888" }}
				/>
			);
		} else {
			retValue = "";
		}
	}
	return retValue;
};

export const dateFormat = (value, formattor) => {
	
	let retValue = value;

	if (value !== null && value !== undefined) {
		// Ensure value is a Date object
		if (typeof value === "string" || typeof value === "number") {
			value = new Date(value);
		}

		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const day = value.getDate();
		const month = months[value.getMonth()];
		const year = value.getFullYear();

		let hours = value.getHours();
		const minutes = value.getMinutes().toString().padStart(2, "0");
		const ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12 || 12;

		switch (formattor.type) {
			case "full date":
				retValue = `${month} ${day}, ${year} ${hours}:${minutes}${ampm}`;
				break;
			case "date only":
				retValue = `${month} ${day}, ${year}`;
				break;
			default:
				retValue = `${month} ${day}, ${year} ${hours}:${minutes}${ampm}`;
				break;
		}
	}

	return retValue;
};

export const linkFormat = (value, source, formattor) => {
	let retValue = value;
	
	if (value !== null && value !== undefined && value !== 'N/A' && source.trim() === "Sycle") {
		retValue = value ? (
			<a href={`${formattor.linkBase.trim() + value.trim()}`} rel="noopener noreferrer" target="_blank">
				{" "}
				{value} <FontAwesomeIcon icon={"arrow-up-right-from-square"} />
			</a>
		) : (
			""
		);
	}
	return retValue;
};

export const buttonFormat = (value, formattor, handleClick) => {
	let retValue = value;
	if (value !== null && value !== undefined) {
		if (formattor.buttonPurpose === "opensOffcanvas") {
			retValue = (
				<Button variant={formattor.buttonVariant} onClick={handleClick}>
					<FontAwesomeIcon icon={formattor.buttonIcon} /> {formattor.buttonText}
				</Button>
			);
		} else {
			// Handle other purposes or default rendering
		}
	}
	return retValue;
};
