import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Row, Col, Stack, Card } from "react-bootstrap";
import { apiRequest } from "./api/Request";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../custom.css";

const UserRolesEditor = ({ userRoles, updateRoles }) => {
	const [uRoles, setURoles] = useState(
		userRoles.map((role) => ({
			...role,
			expandedTerritories: {},
			expandedRegions: {},
		}))
	);

	const [availableRoles, setAvailableRoles] = useState({});
	const [selectedRoleIds, setSelectedRoleIds] = useState(new Set());
	const [groupedClinics, setGroupedClinics] = useState({});

	const [availableTerritories, setAvailableTerritories] = useState({});
	const [availableRegions, setAvailableRegions] = useState({});
	const [availableClinics, setAvailableClinics] = useState({});

	useEffect(() => {
		const getRolesAndGeoEntities = async () => {
			let reqRes = await apiRequest(process.env.REACT_APP_API_URL, "Maintenance/get-roles", "get");
			
			let roleData = reqRes.data;
			
			setAvailableRoles(roleData);

			reqRes = await apiRequest(process.env.REACT_APP_API_URL, "Maintenance/get-geoEntities", "get");
			let geoEntityData = reqRes.data;
			
			setAvailableTerritories(geoEntityData.territories);
			setAvailableRegions(geoEntityData.regions);
			setAvailableClinics(geoEntityData.clinics);
		};

		getRolesAndGeoEntities();
	}, []);

	useEffect(() => {
		// Populate selectedRoleIds based on initially assigned userRoles
		const initialRoleIds = new Set(userRoles.map((ur) => ur.RoleId));
		setSelectedRoleIds(initialRoleIds);
		
	}, [userRoles]);

	const handleRoleChange = (index, newRoleId) => {
		const updatedRoles = [...uRoles];
		const oldRoleId = updatedRoles[index].RoleId;
		updatedRoles[index].RoleId = parseInt(newRoleId, 10);
		setURoles(updatedRoles);
		updateRoles(updatedRoles);

		// Update selectedRoleIds
		setSelectedRoleIds((prevSelectedIds) => {
			const newSelectedIds = new Set(prevSelectedIds);
			if (oldRoleId) {
				newSelectedIds.delete(oldRoleId);
			}
			newSelectedIds.add(parseInt(newRoleId, 10));
			return newSelectedIds;
		});
	};

	const handleDefaultRoleChange = (roleId) => {
		const updatedRoles = uRoles.map((role) => ({
			...role,
			isDefaultRole: role.RoleId === roleId,
		}));

		

		setURoles(updatedRoles);
		updateRoles(updatedRoles);
	};

	const addRole = () => {
		const newRoleId = availableRoles.find((ar) => !selectedRoleIds.has(ar.roleId)).roleId;
		const newRole = {
			RoleId: newRoleId,
			isDefaultRole: uRoles.length === 0,
			Clinics: [],
			Regions: [],
			Territories: [],
			expandedTerritories: {},
			expandedRegions: {},
		};
		setURoles([newRole, ...uRoles]);
		setSelectedRoleIds((prev) => new Set([...prev, newRoleId]));
	};

	const removeRole = (index) => {
		const updatedRoles = [...uRoles];
		const roleIdToRemove = updatedRoles[index].RoleId;
		updatedRoles.splice(index, 1);
		setURoles(updatedRoles);
		updateRoles(updatedRoles);
		setSelectedRoleIds((prev) => {
			const newSelectedIds = new Set(prev);
			newSelectedIds.delete(roleIdToRemove);
			return newSelectedIds;
		});
	};

	const toggleExpanded = (roleIndex, id, type) => {
		const updatedRoles = [...uRoles];
		if (type === "Territory") {
			updatedRoles[roleIndex].expandedTerritories[id] = !updatedRoles[roleIndex].expandedTerritories[id];
		} else if (type === "Region") {
			updatedRoles[roleIndex].expandedRegions[id] = !updatedRoles[roleIndex].expandedRegions[id];
		}
		setURoles(updatedRoles);
	};

	const handleSelectChange = (roleIndex, type, id, isChecked) => {
		const updatedRoles = [...uRoles];
		const role = updatedRoles[roleIndex];
		if (isChecked) {
			if (type === "Territories") {
				// Check the territory and uncheck all regions and clinics within it
				role.Territories.push(id);
				const regionsToUncheck = availableRegions.filter((r) => r.territoryId === id).map((r) => r.regionId);
				role.Regions = role.Regions.filter((rid) => !regionsToUncheck.includes(rid));
				const clinicsToUncheck = availableClinics.filter((c) => regionsToUncheck.includes(c.regionId)).map((c) => c.dwClinicKey);
				role.Clinics = role.Clinics.filter((cid) => !clinicsToUncheck.includes(cid));
			} else if (type === "Regions") {
				// Check the region and uncheck all clinics within it
				role.Regions.push(id);
				const clinicsToUncheck = availableClinics.filter((c) => c.regionId === id).map((c) => c.dwClinicKey);
				role.Clinics = role.Clinics.filter((cid) => !clinicsToUncheck.includes(cid));
			} else {
				// Check the clinic
				role.Clinics.push(id);
			}
		} else {
			// Uncheck the territory, region, or clinic
			role[type] = role[type].filter((t) => t !== id);
		}
		setURoles(updatedRoles);
		updateRoles(updatedRoles);
	};

	return (
		<Col>
			<Card className="p-0 m-3">
				<Card.Header>
					<Row>
						<Col md={{ span: 10 }}>
							<h5>User Roles</h5>
						</Col>
						<Col md={{ span: 2 }} className="text-end">
							<Button variant="outline-primary" onClick={addRole} disabled={uRoles.length >= availableRoles.length}>
								Add New Role
							</Button>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body className="userRoleMapping-card pt-0">
					{/* <Stack direction="horizontal" gap={4} className="p-3 userRoleMapping-stack"> */}
					<Row>
						{availableRoles.length > 0 &&
							availableTerritories.length > 0 &&
							availableRegions.length > 0 &&
							availableClinics.length > 0 &&
							uRoles.map((role, index) => {
								
								const filteredAvailableRoles = availableRoles.filter((ar) => !selectedRoleIds.has(ar.roleId) || ar.roleId === role.RoleId);
								
								return (
									<Col md={{ span: 3 }} key={"userRoleCard-" + role.RoleId}>
										<Card className="mt-4">
											<Card.Header>
												<Row>
													<Col xs={{ span: 6 }}>
														<Form.Select value={role.RoleId} onChange={(e) => handleRoleChange(index, e.target.value)}>
															{filteredAvailableRoles.map((ar) => (
																<option key={ar.roleId} value={ar.roleId}>
																	{ar.roleName}
																</option>
															))}
														</Form.Select>
													</Col>
													<Col xs={{ span: 4 }} className="ps-0">
														<Form.Check
															label="Default"
															style={{ textWrap: "nowrap" }}
															className="pt-2"
															type="radio"
															name="defaultRole"
															id={"defaultRole_" + role.RoleId}
															checked={role.isDefaultRole}
															onChange={() => handleDefaultRoleChange(role.RoleId)}
														/>
													</Col>
													<Col md={{ span: 2 }} className="text-end ps-0">
														<Button className="text-center" variant="outline-danger" onClick={() => removeRole(index)}>
															<FontAwesomeIcon icon="trash-xmark" />
														</Button>
													</Col>
												</Row>
											</Card.Header>
											<Card.Body className="geo-entities-options">
												{availableTerritories.map((territory) => (
													<div key={territory.territoryId}>
														<Form.Check
															id={`checkbox-territory-${territory.territoryId}`}
															label={
																<span>
																	<FontAwesomeIcon
																		icon={"triangle"}
																		rotation={role.expandedTerritories[territory.territoryId] ? 180 : 90}
																		size="2xs"
																		onClick={(e) => {
																			e.preventDefault();
																			e.stopPropagation();
																			toggleExpanded(index, territory.territoryId, "Territory");
																		}}
																		style={{ cursor: "pointer" }}
																	/>{" "}
																	{territory.territoryName}
																</span>
															}
															type="checkbox"
															checked={role.Territories.includes(territory.territoryId)}
															onChange={(e) => handleSelectChange(index, "Territories", territory.territoryId, e.target.checked)}
															ref={(el) => {
																if (el) {
																	el.indeterminate =
																		role.Regions.some((regionId) =>
																			availableRegions
																				.filter((region) => region.territoryId === territory.territoryId)
																				.map((region) => region.regionId)
																				.includes(regionId)
																		) ||
																		role.Clinics.some((clinicId) =>
																			availableClinics
																				.filter((clinic) =>
																					availableRegions
																						.filter((region) => region.territoryId === territory.territoryId)
																						.map((region) => region.regionId)
																						.includes(clinic.regionId)
																				)
																				.map((clinic) => clinic.dwClinicKey)
																				.includes(clinicId)
																		);
																}
															}}
														/>
														{role.expandedTerritories[territory.territoryId] &&
															availableRegions
																.filter((region) => region.territoryId === territory.territoryId)
																.map((region) => (
																	<div style={{ marginLeft: "20px" }} key={region.regionId}>
																		<Form.Check
																			id={`checkbox-region-${region.regionId}`}
																			label={
																				<span>
																					<FontAwesomeIcon
																						icon={"triangle"}
																						rotation={role.expandedRegions[region.regionId] ? 180 : 90}
																						size="2xs"
																						onClick={(e) => {
																							e.preventDefault();
																							e.stopPropagation();
																							toggleExpanded(index, region.regionId, "Region");
																						}}
																						style={{ cursor: "pointer" }}
																					/>{" "}
																					{region.regionName}
																				</span>
																			}
																			type="checkbox"
																			checked={role.Regions.includes(region.regionId)}
																			onChange={(e) => handleSelectChange(index, "Regions", region.regionId, e.target.checked)}
																			ref={(el) => {
																				if (el) {
																					el.indeterminate = role.Clinics.some((clinicId) =>
																						availableClinics
																							.filter((clinic) => clinic.regionId === region.regionId)
																							.map((clinic) => clinic.dwClinicKey)
																							.includes(clinicId)
																					);
																				}
																			}}
																			className={role.Territories.includes(territory.territoryId) ? "checkbox-inherited" : ""}
																			disabled={role.Territories.includes(territory.territoryId)}
																		/>
																		{role.expandedRegions[region.regionId] &&
																			availableClinics
																				.filter((clinic) => clinic.regionId === region.regionId)
																				.map((clinic) => (
																					<div style={{ marginLeft: "20px" }} key={clinic.dwClinicKey}>
																						<Form.Check
																							id={`checkbox-clinic-${clinic.dwClinicKey}`}
																							label={<span>{clinic.clinicName}</span>}
																							type="checkbox"
																							checked={role.Clinics.includes(clinic.dwClinicKey)}
																							onChange={(e) => handleSelectChange(index, "Clinics", clinic.dwClinicKey, e.target.checked)}
																							className={
																								role.Territories.includes(territory.territoryId) || role.Regions.includes(region.regionId)
																									? "checkbox-inherited"
																									: ""
																							}
																							disabled={role.Territories.includes(territory.territoryId) || role.Regions.includes(region.regionId)}
																						/>
																					</div>
																				))}
																	</div>
																))}
													</div>
												))}
											</Card.Body>
										</Card>
									</Col>
								);
							})}
					</Row>
					{/* </Stack> */}
				</Card.Body>
			</Card>
		</Col>
	);
};

UserRolesEditor.propTypes = {
	userRoles: PropTypes.arrayOf(
		PropTypes.shape({
			roleId: PropTypes.number,
			isDefaultRole: PropTypes.bool.isRequired,
			clinics: PropTypes.arrayOf(PropTypes.number),
			regions: PropTypes.arrayOf(PropTypes.number),
			territories: PropTypes.arrayOf(PropTypes.number),
		})
	),
	updateRoles: PropTypes.func,
};

export default UserRolesEditor;
