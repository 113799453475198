// src/Components/CustomNavbar.jsx
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../custom.css";
import logo from "../assets/AudioNovaLogoHorizontal_Trimmed.png";
import AuthenticatedUserDropdown from "./AuthenticatedUserDropdown";
import { getSessionData } from "../Components/security/Helper";
import { apiRequest } from "../Components/api/Request";

const CustomNavbar = ({ page, setPage }) => {
	const [userData, setUserData] = useState(null);
	const [menuItems, setMenuItems] = useState(null);

	const location = useLocation();
	const pathname = location.pathname.toLowerCase();

	useEffect(() => {
		setUserData(getSessionData());
		//getMenuItems();
	}, []);

	let getMenuItems = async () => {
		const linksRes = await apiRequest(process.env.REACT_APP_API_URL, `Dashboard/menu-items?userId=${userData.userId}`, "GET");
		let linksList = linksRes.data;
		setMenuItems(linksList);
	};

	useEffect(() => {
		if (userData) {
			getMenuItems();
		}
	}, [userData]);

	return (
		<Navbar bg="white" variant="light" expand="lg">
			<Container fluid style={{ maxWidth: "1600px" }}>
				<Navbar.Brand href="/Dashboard" key="navbarLogo">
					<img src={logo} className="d-inline-block align-center" alt="Sonova Logo" height="22px" />
				</Navbar.Brand>
				{/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
				<Navbar.Collapse id="basic-navbar-nav" key="navbarOptions" className="ps-5">
					{menuItems &&
						menuItems.length > 0 &&
						menuItems
							.filter((mi) => mi.parentItemId === null)
							.map((mi, indx) => {
								let subMenuItems = menuItems.filter((smi) => smi.parentItemId === mi.menuItemId);
								if (subMenuItems && subMenuItems.length > 0) {
									return (
										<Nav key={"Dynamic-Nav-Master-" + mi.menuItemId} id={"Dynamic-Nav-Master-" + mi.menuItemId}>
											{/* Dropdowns for Menu Items */}
											<NavDropdown
												title={
													<>
														{mi.fontAwesomeIconName && (
															<span className="pe-1">
																<FontAwesomeIcon icon={mi.fontAwesomeIconName ?? ""} />
															</span>
														)}
														<span>{mi.friendlyName}</span>
													</>
												}
												id={mi.linkName + "-dropdown"}
												active={pathname.toLowerCase().trim().includes(mi.linkName.toLowerCase().trim())}>
												{subMenuItems &&
													subMenuItems.map((smi) => {
														return (
															<NavDropdown.Item
																href={`${smi.linkURL}`}
																target={smi.isExternalLink ? "_blank" : ""}
																key={"DynamicSubItem-" + smi.linkName}
																id={"DynamicSubItem-" + smi.linkName}
																active={pathname.toLowerCase().trim() === smi.linkURL.toLowerCase().trim()}>
																{smi.fontAwesomeIconName && (
																	<span className="pe-2">
																		<FontAwesomeIcon icon={smi.fontAwesomeIconName ?? ""} />
																	</span>
																)}
																<span>{smi.friendlyName}</span>
															</NavDropdown.Item>
														);
													})}
											</NavDropdown>
										</Nav>
									);
								} else {
									return (
										<Nav key={"Dynamic-Nav-Master-" + mi.menuItemId} id={"Dynamic-Nav-Master-" + mi.menuItemId}>
											<Nav.Link
												href={`${mi.linkURL}`}
												target={mi.isExternalLink ? "_blank" : ""}
												active={pathname.toLowerCase().trim().includes(mi.linkName.toLowerCase().trim())}>
												{mi.fontAwesomeIconName && (
													<span className="pe-1">
														<FontAwesomeIcon icon={mi.fontAwesomeIconName ?? ""} />
													</span>
												)}
												<span>{mi.friendlyName}</span>
											</Nav.Link>
										</Nav>
									);
								}
							})}
					<Nav xs={4} className="ms-auto" style={{ display: "flex", justifyContent: "end" }}>
						<div
							style={{
								marginRight: "10px",
								paddingTop: "15px",
								display: "flex",
								justifyContent: "right",
							}}>
							{userData && "Welcome " + userData.firstName + " " + userData.lastName}
						</div>
						<AuthenticatedUserDropdown />
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

CustomNavbar.propTypes = {
	page: PropTypes.string.isRequired,
	setPage: PropTypes.func.isRequired,
};

export default CustomNavbar;
