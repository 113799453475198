import axios from "axios";

export const apiRequest = async (apiUrl, endpoint, method, data) => {
    const config = {
		method,
		url: `${apiUrl}${endpoint}`,
		headers: { "Content-Type": "application/json;charset=UTF-8" },
		data,
	};

	return axios(config)
		.then((response) => response)
		.catch((error) => {
			console.error(error);
		});
};
