import { useEffect, useState, useCallback } from "react";
import { Row, Col, Card, Alert, Spinner } from "react-bootstrap";
import moment from "moment";
import WidgetCard from "../Components/widgetCard";
import WidgetColumn from "../Components/widgetColumn";
import DynamicChart from "../Components/dynamicChart";
import deepMerge from "../Functions/jsonDeepMerge";
import { apiRequest } from "../Components/api/Request";
import Loader from "../Components/loader/Loader";
import { amountFormat, percentageFormat } from "../Components/common/Helper";
import "../custom.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";

const Dashboard = ({ filterOptions, selectedSort, selectedGrouping, onIsAllWidgetsLoaded }) => {
	let { dashboardId } = useParams();
	let dashboardIdFromSession = JSON.parse(sessionStorage.getItem("selectedDashboard"));

	const location = useLocation();
	const pathname = location.pathname.toLowerCase();
	const navigate = useNavigate();

	if (!dashboardId && dashboardIdFromSession && pathname && pathname.trim().toLocaleLowerCase() === "/dashboard") {
		navigate(`/Dashboard/${dashboardIdFromSession}`);
	}

	const [dashboardWidgets, setDashboardWidgets] = useState([]);
	const [chartTypes, setChartTypes] = useState([]);
	const [widgetDataLoaded, setWidgetDataLoaded] = useState(false);
	const [updatedWidgets, setUpdatedWidgets] = useState([]);
	const [widgetLoading, setWidgetLoading] = useState({});
	const [allWidgetsLoaded, setAllWidgetsLoaded] = useState(false);
	const [widgetError, setWidgetError] = useState({});
	const [isSearching, setIsSearching] = useState(false);
	const [selectedDashboard, setSelectedDashboard] = useState(dashboardId);

	useEffect(() => {
		if (selectedDashboard) {
			const initializeDashboard = async () => {
				try {
					setUpdatedWidgets([]);
					setIsSearching(true);
					// Fetch chart types
					const chartTypesRes = await apiRequest(process.env.REACT_APP_API_URL, "Dashboard/fetchChartTypes", "GET");
					let chartTypesData = chartTypesRes.data;
					setChartTypes(chartTypesData);

					// Fetch widgets for the selectedDashboard
					const widgetsRes = await apiRequest(process.env.REACT_APP_API_URL, `Dashboard/getWidgets?dashboardId=${selectedDashboard}`, "GET");
					setIsSearching(false);

					let widgets = widgetsRes.data;

					const transformedWidgets = widgets.map((widget) => {
						try {
							widget.extraText = typeof widget.extraText === "string" && widget.extraText.trim().length > 0 ? JSON.parse(widget.extraText) : [];

							widget.replacementJson =
								typeof widget.replacementJson === "string" && widget.replacementJson.trim().length > 0 ? JSON.parse(widget.replacementJson) : widget.replacementJson || {};

							let thisWidgetsChartType = chartTypesData.find((ct) => ct.chartTypeId === widget.chartTypeId);

							// Ensure both are objects
							const baseJson = typeof thisWidgetsChartType.baseJson === "string" ? JSON.parse(thisWidgetsChartType.baseJson) : thisWidgetsChartType.baseJson;

							widget.fullChartJSON = deepMerge(baseJson, widget.replacementJson);

							widget.chartTypeName = thisWidgetsChartType.friendlyName;
						} catch (error) {
							console.error(`Failed to parse widget data for widget ${widget.widgetId}:`, error);
							widget.extraText = [];
							widget.replacementJson = {};
							widget.fullChartJSON = {};
						}
						return widget;
					});

					setDashboardWidgets(transformedWidgets);

					setWidgetDataLoaded(true);
				} catch (error) {
					console.error("Initialization failed:", error);
				}
			};

			initializeDashboard();
		}
	}, [selectedDashboard]);

	useEffect(() => {
		if (widgetLoading) {
			const hasTrue = Object.values(widgetLoading).some((value) => value === true);
			setAllWidgetsLoaded(!hasTrue);
		} else {
			setAllWidgetsLoaded(false);
		}
	}, [widgetLoading]);

	useEffect(() => {
		if (selectedDashboard) {
			onIsAllWidgetsLoaded(allWidgetsLoaded);
		}
	}, [selectedDashboard, allWidgetsLoaded]);

	function formatAxisLabel(value) {
		if (value >= 1000000) {
			// If the value is 1 million or more, divide by 1 million and append 'm'
			return (value / 1000000).toFixed(1) + "m";
		} else if (value >= 1000) {
			// If the value is 1,000 or more but less than 1 million, divide by 1,000 and append 'k'
			return (value / 1000).toFixed(1) + "k";
		}
		// Return the original value if less than 1,000
		return value.toString();
	}

	const fetchWidgetData = useCallback(async () => {
		const wereAllOrNoProvidersSelected = sessionStorage.getItem("allProvidersSelected");
		var allProviders = wereAllOrNoProvidersSelected === "true";

		let filterData = {
			startDate: moment(filterOptions.startDate).format("YYYY-MM-DD"),
			endDate: moment(filterOptions.endDate).format("YYYY-MM-DD"),
			clinics: filterOptions.clinics,
			providers: allProviders === true ? null : filterOptions.providers,
			groupByClinic: selectedGrouping === "Clinic" ?? null,
			groupByRegion: selectedGrouping === "Region" ?? null,
			groupByTerritory: selectedGrouping === "Territory" ?? null,
			groupByAll: selectedGrouping === "All" ?? null,
			//isIndividualReport: true,
		};

		if (filterData.startDate && filterData.endDate && filterData.clinics) {
			setIsSearching(true);

			dashboardWidgets.forEach(async (widget, i) => {
				if (widget.apiEndpoint) {
					setUpdatedWidgets((currentWidgets) => {
						// const index = currentWidgets.findIndex((w) => w.dashboardRow === widget.dashboardRow
						//                                            && w.dashboardColumn === widget.dashboardColumn
						// 										   && w.widgetSubPosition === widget.widgetSubPosition);
						const index = currentWidgets.findIndex((w) => w.widgetId === widget.widgetId);
						const newWidgets = [...currentWidgets];
						if (index !== -1) {
							newWidgets[index] = {
								...newWidgets[index],
							};
						} else {
							newWidgets.push({
								...widget,
							});
						}
						return newWidgets;
					});

					setWidgetLoading((prev) => ({ ...prev, [widget.widgetId]: true })); // Start loading
					setWidgetError((prev) => ({ ...prev, [widget.widgetId]: false })); // Remove Errors
					try {
						let widgetConfig = JSON.parse(widget.apiEndpoint);
						let newFullChartJSON = { ...widget.fullChartJSON };
						let newExtraTextJSON = widget.extraText;
						let widgetData = null;

						if (widget.chartTypeName === "Horizontal Bar Chart") {
							filterData.thresholds = widget.fullChartJSON.yAxis.data;
						}
						if (widget.chartTypeName === "Vertical Bar Chart") {
							filterData.thresholds = widget.fullChartJSON.xAxis.data;
						}

						if (widget.chartTypeName === "Watermark Custom" && filterData.clinics.length !== 1) {
							newFullChartJSON = {
								warning: "Select only one clinic to view this widget",
							};
						} else {
							const widgetDataRes = await apiRequest(process.env.REACT_APP_API_URL, widgetConfig.widgetEndpoint, "POST", filterData);
							if (widgetDataRes.status !== 200) {
								throw new Error("Failed to run " + filterData);
							}
							widgetData = widgetDataRes.data;
						}

						// Helper function to determine label rotation
						function getRotation(number) {
							if (number === null) return 0;
							return number.toString().replace(".", "").length > 3 ? 90 : 0;
						}

						// Helper function to adjust label font size
						function getFontSize(number) {
							return getRotation(number) === 90 ? 3 : 8;
						}

						// Helper function to adjust label offset based on rotation
						function getLabelOffset(number) {
							if (number === null) return [0, 0]; // Return no offset if there is no number.

							let numberString = number.toString(); // Ensure number is treated as a string.
							let extraDigits = Math.max(0, numberString.length - 5); // Calculate digits beyond the first three.
							let verticalOffset = 13 + extraDigits * 3; // Compute vertical offset based on extra digits.

							return getRotation(number) === 90 ? [verticalOffset, 7] : [0, 0]; // Apply offset if rotated.
						}

						function getBeatGoal(value, goal, direction) {
							if (direction === "higher") {
								return value >= goal;
							} else {
								return value <= goal;
							}
						}

						// Modify widget.fullChartJSON with widgetData AND extra Text here
						switch (widget.chartTypeName) {
							case "Arc Gauge":
								let chartMax = widgetData.gaugeMaximum;
								let chartMin = widgetData.gaugeMinimum;

								chartMax = chartMax === chartMin ? chartMax + 1 : chartMax;

								newFullChartJSON.series[0].max = chartMax;
								newFullChartJSON.series[0].min = chartMin;
								let chartGoal = widgetData.widgetGoal / (chartMax === 0 ? 1 : chartMax);
								let tooltipGoal = widgetData.widgetGoal ?? 0;
								newFullChartJSON.series[0].axisLine.lineStyle.color[0][0] = chartGoal - 0.00001; //This should insert into the number slot of the first color of the chart
								//newFullChartJSON.series[0].data[0].value = (widgetData.noDataWarning ? "No Data" : widgetData.widgetValue);

								newFullChartJSON.tooltip = {
									// Custom formatter for tooltip to include chartGoal
									formatter: function (params) {
										return `${params.seriesName}<br/>Value: ${params.value.toLocaleString()}<br/>Goal: ${tooltipGoal.toLocaleString()}`;
									},
								};

								newFullChartJSON.series[0].splitNumber = chartMax <= 10 ? Math.min(chartMax * 2, 15) : 12;

								newFullChartJSON.series[0].data[0].value = widgetData.widgetValue;

								newFullChartJSON.series[0].detail.formatter =
									widgetData.unit === "$"
										? widgetData.unit + widgetData.widgetValue.toLocaleString()
										: widgetData.widgetValue.toLocaleString() + " " + widgetData.unit + (widgetData.widgetValue === 1 ? "" : "s");

								newFullChartJSON.series[0].axisLabel = {
									formatter: function (value) {
										return formatAxisLabel(value);
									},
									fontSize: 9, // Adjust fontSize if needed
									color: "inherit", // or any specific color as required
								};
								break;
							case "Bar Gauge":
								newFullChartJSON.series[0].itemStyle.color =
									widgetData.widgetValue < widgetData.goal ? newFullChartJSON.series[2].itemStyle.color : newFullChartJSON.series[3].itemStyle.color;
								newFullChartJSON.series[0].data[0].value = widgetData.widgetValue;

								newFullChartJSON.series[1].label.formatter = "Goal: " + (widgetData.goalIsGreaterThan ? ">" : "<") + widgetData.goal + "%";
								newFullChartJSON.series[1].data[0].value = widgetData.goal;

								newFullChartJSON.series[2].data[0].value = widgetData.goal;
								newFullChartJSON.series[3].data[0].value = 100 - widgetData.goal;

								widget.extraText[0].value = widgetData.widgetValue.toLocaleString();
								widget.extraText[1].value = widgetData.numerator.toLocaleString();
								widget.extraText[2].value = widgetData.denominator.toLocaleString();

								break;
							case "Horizontal Bar Chart":
								const hColorList = newFullChartJSON.series[0].itemStyle.color;
								newFullChartJSON.series[0].data = widgetData.widgetValues.map((value, index) => ({
									value: value === null ? 0 : value, // Use 0 for bar length when there's no data
									itemStyle: {
										color: hColorList[index],
									},
									label: {
										show: true,
										position: "right",
										align: "center",
										rich: {
											noData: {
												fontStyle: "italic",
												fontSize: 8,
											},
										},
										formatter: value === null ? "{noData|No\nData}" : `{c}`, // Use rich text style for "No Data"
									},
								}));
								newFullChartJSON.series[0].label = {
									...newFullChartJSON.series[0].label,
									rich: { noData: { fontStyle: "italic", fontSize: 8 } },
								};
								widget.extraText[0].value = widgetData.widgetAverage;
								widget.extraText[0].goal = widgetData.averageGoal;
								widget.extraText[0].goalDirection = widgetData.goalDirection;

								break;
							case "Vertical Bar Chart":
								const vColorList = newFullChartJSON.series[0].itemStyle.color;

								// Combine labels and values into an array of objects to sort them together
								const labeledValues = widgetData.widgetLabels.map((label, index) => {
									const value = widgetData.widgetValues[index];
									const goal = (widgetData.widgetGoals && widgetData.widgetGoals[index]) ?? value;
									const perToGoal = goal === 0 || value === null ? 0 : value / goal; // Avoid division by zero

									return {
										label,
										value,
										tooltipLabel: widgetData.widgetTooltipLabels ? widgetData.widgetTooltipLabels[index] : null,
										goal,
										perToGoal,
									};
								});

								// Sort the combined array by selected Sort Order
								switch (selectedSort) {
									case "alpha":
										labeledValues.sort((a, b) => a.label.localeCompare(b.label));
										break;
									case "asc":
										labeledValues.sort((a, b) => Number(a.value) - Number(b.value));
										break;
									case "goal":
										labeledValues.sort((a, b) => Number(a.perToGoal) - Number(b.perToGoal));
										break;
									default:
										labeledValues.sort((a, b) => a.label.localeCompare(b.label));
										break;
								}

								// Map the sorted data back into the chart's data structure
								newFullChartJSON.series[0].data = labeledValues.map((item, index) => ({
									value: item.value === null ? 0 : item.value,
									itemStyle: {
										color: getBeatGoal(item.value, item.goal, widgetData.goalDirection) ? newFullChartJSON["goalPassedColor"] : newFullChartJSON["goalFailedColor"], //vColorList[index % vColorList.length],
									},
									label: {
										show: true,
										position: "top",
										align: "center",
										verticalAlign: "bottom",
										offset: getLabelOffset(item.value),
										rotate: getRotation(item.value),
										rich: {
											noData: {
												fontStyle: "italic",
												fontSize: getFontSize(item.value),
											},
										},
										formatter: function (params) {
											return item.value === null ? "{noData|No\nData}" : `${item.value.toLocaleString()}`;
										},
									},
									tooltipLabel: item.tooltipLabel,
									perToGoal: item.perToGoal,
								}));

								// Update the xAxis labels after sorting
								newFullChartJSON.xAxis.data = labeledValues.map((item) => item.label);

								newFullChartJSON.tooltip = {
									trigger: "item",
									formatter: (params) => {
										const tooltipLabel = params.data.tooltipLabel || params.name;
										return ` ${params.data.value.toLocaleString()}${tooltipLabel}`;
									},
								};
								//- ${params.data.perToGoal}
								break;

							case "Big Number":
								newExtraTextJSON[0] = {
									...newExtraTextJSON[0],
									// value: Number(
									// 	Math.round(widgetData.widgetValue * Math.pow(10, newExtraTextJSON[0].rounding ?? 0)) / Math.pow(10, newExtraTextJSON[0].rounding ?? 0)
									// ).toLocaleString(undefined, { minimumFractionDigits: newExtraTextJSON[0].rounding ?? 0 }),

									value: amountFormat(widgetData.widgetValue, { decimalPoint: newExtraTextJSON[0].rounding ?? 2, format: "round" }),

									goal: widgetData.goal,
								};
								newExtraTextJSON[1] = {
									...newExtraTextJSON[1],
									// value: Number(Math.round(widgetData.numerator * Math.pow(10, newExtraTextJSON[1].rounding ?? 0)) / Math.pow(10, newExtraTextJSON[1].rounding ?? 0)).toLocaleString(
									// 	undefined,
									// 	{ minimumFractionDigits: newExtraTextJSON[1].rounding ?? 0 }
									// ),
									value: amountFormat(widgetData.numerator, { decimalPoint: newExtraTextJSON[1].rounding ?? 2, format: "round" }),
								};
								newExtraTextJSON[2] = {
									...newExtraTextJSON[2],
									// value: Number(
									// 	Math.round(widgetData.denominator * Math.pow(10, newExtraTextJSON[2].rounding ?? 0)) / Math.pow(10, newExtraTextJSON[2].rounding ?? 0)
									// ).toLocaleString(undefined, { minimumFractionDigits: newExtraTextJSON[2].rounding ?? 0 }),
									value: amountFormat(widgetData.denominator, { decimalPoint: newExtraTextJSON[2].rounding ?? 2, format: "round" }),
								};

								break;
							case "Watermark Custom":
								if (filterData.clinics.length !== 1) {
									newFullChartJSON = {
										warning: "Select only one clinic to view this widget",
									};
								} else {
									newFullChartJSON = {};

									if (widgetData.watermarkDays.length === 0) {
										newFullChartJSON = {
											warning: "Clinic has no watermark data for today",
										};
									} else {
										newFullChartJSON.watermarks = widgetData.watermarkDays;

										let acknowledgement = {};
										acknowledgement.status = widgetData.acknowledgement;
										acknowledgement.reason = widgetData.acknowledgementReason;
										acknowledgement.user = widgetData.acknowledgedBy;
										acknowledgement.time = widgetData.acknowledgedOn;

										newFullChartJSON.clinic = JSON.parse(sessionStorage.getItem("selectedClinics"))[0];

										newFullChartJSON.acknowledgement = acknowledgement;
									}
								}

								break;
							case "Watermark Regional":
								newFullChartJSON = {};
								newFullChartJSON.watermarkHistories = widgetData;

								break;
							case "Donut Chart":
								widgetData.widgetValues.forEach((value, idx) => {
									newFullChartJSON.series[0].data[idx] = {
										value: value,
										name: widgetData.widgetLabels[idx],
										itemStyle: newFullChartJSON.series[0].data[idx].itemStyle,
										// Conditionally add secondary value if it exists
										secondary: widgetData.widgetSecondaryValues ? widgetData.widgetSecondaryValues[idx] : null,
									};
								});

								newFullChartJSON.series[0].emphasis.label.formatter = function (params) {
									let label = `${params.name}\n ${amountFormat(params.value, newFullChartJSON.amountFormattors[0])} ${
										newExtraTextJSON[0] ? newExtraTextJSON[0].unit : ""
									} (${percentageFormat((params.value / widgetData.widgetTotal) * 100, {
										displaySign: true,
										format: "round",
										decimalPoint: 0,
									})})`;
									if (params.data.secondary !== null) {
										label += `\n${amountFormat(params.data.secondary, newFullChartJSON.amountFormattors[1])} ${newExtraTextJSON[1] ? newExtraTextJSON[1].unit : ""}`;
									}
									return label;
								};

								newExtraTextJSON[0].value = amountFormat(widgetData.widgetTotal, newFullChartJSON.amountFormattors[0]);

								if (newExtraTextJSON[1]) {
									newExtraTextJSON[1].value = amountFormat(widgetData.widgetSecondaryTotal, newFullChartJSON.amountFormattors[1]);
								}

								break;
							case "Stacked Column Chart":
								// Step 1: Reset data arrays for each series to ensure new data completely replaces old data
								newFullChartJSON.series.forEach((series) => {
									series.data = []; // Clear existing data
								});
								newFullChartJSON.xAxis[0].data = [];

								// Step 2: Process data and update chart
								const labeledStackedValues = widgetData.widgetLabels.map((label, index) => {
									const values = widgetData.widgetValues.map((valueSet, setIndex) => valueSet[index]);
									const valueTotal = values.reduce((x, y) => x + y, 0);
									const secondaryValues = widgetData.widgetSecondaryValues ? widgetData.widgetSecondaryValues.map((valueSet, setIndex) => valueSet[index]) : [];
									const secondaryTotal = widgetData.widgetSecondaryValues ? secondaryValues.reduce((x, y) => x + y, 0) : null;

									return {
										label,
										values,
										secondaryValues,
										tooltipLabel: widgetData.widgetTooltipLabels ? widgetData.widgetTooltipLabels[index] : null,
										valueTotal,
										secondaryTotal,
									};
								});

								// Step 3: Sort the combined array by selected Sort Order
								switch (selectedSort) {
									case "alpha":
										labeledStackedValues.sort((a, b) => a.label.localeCompare(b.label));
										break;
									case "asc":
										labeledStackedValues.sort((a, b) => Number(a.valueTotal) - Number(b.valueTotal));
										break;
									default:
										labeledStackedValues.sort((a, b) => a.label.localeCompare(b.label));
										break;
								}

								// Step 4: Assign new data to each series and update xAxis labels
								labeledStackedValues.forEach((item, itemIndex) => {
									item.values.forEach((value, seriesIndex) => {
										newFullChartJSON.series[seriesIndex].data[itemIndex] = {
											value: value,
											secondary: item.secondaryValues[seriesIndex],
											itemStyle: {
												color: newFullChartJSON.series[seriesIndex].itemStyle.color,
											},
											label: {
												show: seriesIndex === item.values.length - 1, // Show label only for the last series in the stack
												position: "top",
												align: "center",
												verticalAlign: "bottom",
												offset: getLabelOffset(item.valueTotal),
												rotate: getRotation(item.valueTotal),
												rich: {
													noData: {
														fontStyle: "italic",
														fontSize: getFontSize(item.valueTotal),
													},
												},
												formatter: function () {
													return item.valueTotal === null ? "{noData|No\nData}" : `${amountFormat(item.valueTotal, newFullChartJSON.amountFormattors[0])}`;
												},
											},
											tooltipLabel: item.tooltipLabel, // Attach the tooltip label for display
										};
									});
									newFullChartJSON.xAxis[0].data[itemIndex] = item.label;
								});

								// Step 5: Configure tooltip
								newFullChartJSON.tooltip = {
									trigger: "axis",
									axisPointer: {
										type: "shadow",
									},
									formatter: function (params) {
										let primaryTotal = 0;
										let secondaryTotal = 0;
										let tooltipLabel = params[0].data.tooltipLabel || params[0].name;
										const tooltipContent = params
											.map((p) => {
												primaryTotal += p.value;
												secondaryTotal += p.data.secondary || 0;
												const primaryFormatted = amountFormat(p.value, newFullChartJSON.amountFormattors[0]);
												const secondaryFormatted = p.data.secondary
													? " - " + amountFormat(p.data.secondary, newFullChartJSON.amountFormattors[1]) + ` ${newFullChartJSON.amountFormattors[1].unit ?? ""}`
													: "";
												return `${p.seriesName}: ${primaryFormatted}${secondaryFormatted}`;
											})
											.join("<br/>");

										return `<strong>${tooltipLabel}</strong><br/>${tooltipContent}<br/><strong>Total: ${amountFormat(
											primaryTotal,
											newFullChartJSON.amountFormattors[0]
										)}</strong><br/><strong>${newFullChartJSON.amountFormattors[1].unit ?? ""}: ${amountFormat(secondaryTotal, newFullChartJSON.amountFormattors[1])}</strong>`;
									},
								};

								break;
							// Add more cases as needed
							default:
								console.warn(`Unhandled chart type: ${widget.chartTypeName}`);
						}

						widget.fullChartJSON = newFullChartJSON;
						widget.extraText = newExtraTextJSON;

						setUpdatedWidgets((currentWidgets) => {
							// const index = currentWidgets.findIndex((w) => w.dashboardRow === widget.dashboardRow
							//                                            && w.dashboardColumn === widget.dashboardColumn
							// 										   && w.widgetSubPosition === widget.widgetSubPosition);
							const index = currentWidgets.findIndex((w) => w.widgetId === widget.widgetId);
							const newWidgets = [...currentWidgets];
							if (index !== -1) {
								newWidgets[index] = {
									...newWidgets[index],
									fullChartJSON: newFullChartJSON,
									extraText: newExtraTextJSON,
								};
							} else {
								newWidgets.push({
									...widget,
									fullChartJSON: newFullChartJSON,
									extraText: newExtraTextJSON,
								});
							}
							return newWidgets;
						});
					} catch (error) {
						console.error(`Failed to fetch data for widget ${widget.widgetId}:`, error);
						setWidgetError((prev) => ({ ...prev, [widget.widgetId]: true })); // Set Error
					}
					setWidgetLoading((prev) => ({ ...prev, [widget.widgetId]: false })); // Stop loading
				}
				return widget;
			});

			//setUpdatedWidgets(dataInsertedWidgets);

			setIsSearching(false);
		}
	}, [dashboardWidgets, filterOptions, selectedSort, selectedGrouping]);

	useEffect(() => {
		if (widgetDataLoaded) {
			fetchWidgetData();
		}
	}, [widgetDataLoaded, fetchWidgetData]);

	const renderWidgets = () => {
		const rows = {};

		updatedWidgets.forEach((widget) => {
			if (!rows[widget.dashboardRow]) {
				rows[widget.dashboardRow] = {};
			}
			if (!rows[widget.dashboardRow][widget.dashboardColumn]) {
				rows[widget.dashboardRow][widget.dashboardColumn] = [];
			}
			rows[widget.dashboardRow][widget.dashboardColumn].push(widget);
		});

		return Object.keys(rows).map((rowKey) => {
			const columns = rows[rowKey];

			return (
				<Row key={`row-${rowKey}`}>
					{Object.keys(columns).map((colKey) => {
						const columnWidgets = columns[colKey];
						if (columnWidgets.length === 1 && columnWidgets[0].widgetSubPosition === null) {
							let thisWidgetsChartType = chartTypes.find((ct) => ct.chartTypeId === columnWidgets[0].chartTypeId);
							return (
								<WidgetCard size={columnWidgets[0].size} key={columnWidgets[0].widgetId}>
									<Card.Body style={{ height: "100%" }}>
										{widgetLoading[columnWidgets[0].widgetId] ? (
											<div className="widget-loader">
												<Spinner animation="border" role="status">
													<span className="sr-only">Loading...</span>
												</Spinner>
											</div>
										) : widgetError[columnWidgets[0].widgetId] ? (
											<div className="widget-loader">
												<Alert variant="warning">Error loading {columnWidgets[0].chartName}. Please try again.</Alert>
											</div>
										) : (
											<DynamicChart
												chartName={columnWidgets[0].chartName}
												chartOptions={columnWidgets[0].fullChartJSON}
												reportUrl={JSON.parse(columnWidgets[0].apiEndpoint).reportURL}
												chartType={thisWidgetsChartType.friendlyName}
												chartSize={columnWidgets[0].size}
												extraText={columnWidgets[0].extraText}
												popoverTitle={columnWidgets[0].chartName}
												popoverText={columnWidgets[0].popoverText}
												keyProp={columnWidgets[0].chartName}
											/>
										)}
									</Card.Body>
								</WidgetCard>
							);
						} else {
							return (
								<WidgetColumn key={`widget-column-${colKey}`}>
									{columnWidgets.map((widget) => {
										let thisWidgetsChartType = chartTypes.find((ct) => ct.chartTypeId === widget.chartTypeId);

										return (
											<WidgetCard size={widget.size} key={widget.widgetId}>
												<Card.Body style={{ height: "100%" }}>
													{widgetLoading[widget.widgetId] ? (
														<div className="widget-loader">
															<Spinner animation="border" role="status">
																<span className="sr-only">Loading...</span>
															</Spinner>
														</div>
													) : widgetError[widget.widgetId] ? (
														<div className="widget-loader">
															<Alert variant="warning">Error loading {widget.chartName}. Please try again.</Alert>
														</div>
													) : (
														<DynamicChart
															chartName={widget.chartName}
															chartOptions={widget.fullChartJSON}
															reportUrl={JSON.parse(widget.apiEndpoint).reportURL}
															chartType={thisWidgetsChartType.friendlyName}
															chartSize={widget.size}
															extraText={widget.extraText}
															popoverTitle={widget.chartName}
															popoverText={widget.popoverText}
															keyProp={widget.chartName}
														/>
													)}
												</Card.Body>
											</WidgetCard>
										);
									})}
								</WidgetColumn>
							);
						}
					})}
				</Row>
			);
		});
	};

	if (selectedDashboard) {
		return (
			<>
				{isSearching && <Loader message="Loading..." />}
				{updatedWidgets && Array.isArray(updatedWidgets) && updatedWidgets.length > 0 ? (
					renderWidgets()
				) : (
					<Row className="justify-content-md-center pt-4">
						<Col md={6}>
							<Alert variant="warning" className="text-center">
								Please choose a dashboard with Widgets
							</Alert>
						</Col>
					</Row>
				)}
			</>
		);
	} else {
		return (
			<div className="d-flex justify-content-center">
				<Alert variant="warning" className="text-center w-50">
					No dashboard to load
				</Alert>
			</div>
		);
	}
};

export default Dashboard;
