import React, { useEffect, useState } from "react";
import Table from "../Components/common/Table";
import Loader from "../Components/loader/Loader";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { apiRequest } from "../Components/api/Request";
import { amountFormat, percentageFormat, booleanFormat, linkFormat } from "../Components/common/Helper";
import sanitizeFilename from "../Functions/sanitizeFilename";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/Reports.css";

const Report = ({ filterOptions }) => {
	const [reportDetails, setReportDetails] = useState(null);
	const [reportTableColumn, setReportTableColumn] = useState(null);
	const [reportData, setReportData] = useState(null);
	const [reports, setReports] = useState(null);
	const [totalAggregateRow, setTotalAggregateRow] = useState(null);
	const [isSearching, setIsSearching] = useState(false);
	const [noReportFound, setNoReportFound] = useState(false);
	const [errorFound, setErrorFound] = useState(false);
	const { reportName } = useParams();
	const [allowPaginationOnTable, setAllowPaginationOnTable] = useState("yes");

	useEffect(() => {
		if (reportName.toLowerCase() != "powerbi") {
			getReportDetails();
		}
	}, []);

	useEffect(() => {
		if (filterOptions && !isEmpty(filterOptions) && reportDetails) {
			getReportData();
		}
	}, [reportDetails, filterOptions]);

	let getReportDetails = async () => {
		setIsSearching(true);
		let response = await apiRequest(process.env.REACT_APP_API_URL, `Reports?urlName=${reportName}`, "get");

		if (response.status == 200) {
			setReportDetails(response.data);

			let parsedConfigData = JSON.parse(response.data.config);
			if (parsedConfigData.displayMultipleReports) {
				let columnsData = {};

				parsedConfigData.columns.map(async (column) => {
					let tableColumns = column.tableColumns;
					const updatedTableColums = columnMap(tableColumns);
					columnsData[column.name + "_column"] = updatedTableColums;
				});
				setReportTableColumn(columnsData);
			} else {
				let tableColumns = parsedConfigData.tableColumns;
				const updatedTableColums = columnMap(tableColumns);

				setReportTableColumn({ report_column: updatedTableColums });
			}

			setAllowPaginationOnTable(parsedConfigData.allowPagination ? parsedConfigData.allowPagination : "yes");
		} else if (response.status == 204) {
			setNoReportFound(true);
			setIsSearching(false);
		} else {
			console.error(response);
			setIsSearching(false);
		}
	};

	let columnMap = (columns) => {
		return columns.map((column) => {
			let newColumn = column;

			// Apply predefined formatter cells directly if specified
			if (newColumn.amountFormattor) {
				newColumn = { ...newColumn, Cell: amountFormattorCell }; // Use the existing function directly
			} else if (newColumn.percentageFormattor) {
				newColumn = { ...newColumn, Cell: percentageFormattorCell }; // Use the existing function directly
			} else if (newColumn.booleanFormattor) {
				newColumn = { ...newColumn, Cell: booleanFormattorCell };
			} else if (newColumn.linkFormattor) {
				newColumn = { ...newColumn, Cell: linkFormattorCell };
			}

			// Handling hidden columns
			if (newColumn.hiddenColumn) {
				newColumn.show = false; // Mark column as hidden but still processable
			}

			// Preserve original cell rendering
			const originalCell = newColumn.Cell || ((props) => props.value);

			// Enhance the Cell to handle conditional rendering based on row type
			newColumn.Cell = (props) => {
				const { row } = props;
				const rowType = row.values.source; // Assumes 'type' is available in row data

				const rowDepth = row.depth;

				// Determine if certain columns should be hidden based on the row type

				// Apply conditional visibility rules
				if (newColumn.show === false) {
					return null; // Skip rendering but keep processing
				}

				if (
					(rowType === "TERRITORY AGGREGATE" && ["regionName", "clinicName", "source", "rcCode"].includes(column.accessor)) ||
					(rowType === "REGIONAL AGGREGATE" && ["regionName", "clinicName", "source", "rcCode"].includes(column.accessor)) ||
					(rowType === "TOTAL AGGREGATE" && ["territory", "regionName", "clinicName", "source", "rcCode"].includes(column.accessor)) ||
					(rowDepth > 0 && !["TERRITORY AGGREGATE", "REGIONAL AGGREGATE"].includes(rowType) && ["territory", "regionName"].includes(column.accessor))
				) {
					return null; // Do not render the column for these row types
				}

				// Render expansion icon with FontAwesome if applicable
				if (column.canExpand && row.original.name && !isEmpty(row.original.name)) {
					return (
						<div style={{ cursor: "pointer", marginLeft: rowDepth * 20 }}>
							<span {...row.getToggleRowExpandedProps()}>
								<Row>
									<Col md={2}>
										<FontAwesomeIcon icon="triangle" rotation={row.isExpanded ? 180 : 90} size="2xs" />
									</Col>
									<Col md={10} style={{ textWrap: "nowrap" }}>
										{row.original.name}
									</Col>
								</Row>
							</span>
						</div>
					);
				}

				// Default rendering for non-special cases
				return originalCell(props);
			};

			// Recursively apply this mapping to any nested columns
			if (column.columns) {
				newColumn.columns = columnMap(column.columns);
			}

			return newColumn;
		});
	};

	let amountFormattorCell = (props) => {
		let val = amountFormat(props.value, props.column.amountFormattor);
		return val;
	};

	let percentageFormattorCell = (props) => {
		let val = percentageFormat(props.value, props.column.percentageFormattor);
		return val;
	};

	let booleanFormattorCell = (props) => {
		let val = booleanFormat(props.value, props.column.booleanFormattor);
		return val;
	};

	let linkFormattorCell = (props) => {
		let val = linkFormat(props.value, props.row.values.source, props.column.linkFormattor);
		return val;
	};

	const isEmpty = (obj) => {
		return Object.keys(obj).length === 0;
	};

	let callReportEndpoint = async (endPointConfig, filterData, filterDataConfig) => {
		let data = {};

		if (filterDataConfig) {
			filterDataConfig.map((config) => {
				let value = config.value ? config.value : config.accessor ? filterData[config.accessor] : "";
				data[config.name] = value;
			});
		} else {
			data = filterData;
		}

		let reqRes = await apiRequest(process.env.REACT_APP_API_URL, endPointConfig.endPoint, endPointConfig.type ? endPointConfig.type : "get", data);
		return reqRes;
	};

	let getReportData = async () => {
		const wereAllOrNoProvidersSelected = sessionStorage.getItem("allProvidersSelected");
		var allProviders = wereAllOrNoProvidersSelected === "true";

		let endPointConfig = JSON.parse(reportDetails.endPointConfig);
		let filterData = {
			startDate: moment(filterOptions.startDate).format("YYYY-MM-DD"),
			endDate: moment(filterOptions.endDate).format("YYYY-MM-DD"),
			clinics: filterOptions.clinics,
			providers: allProviders === true ? null : filterOptions.providers,
			isIndividualReport: endPointConfig.isIndividualReport,
			thresholds: endPointConfig.thresholds ?? [],
		};

		if (filterData.startDate && filterData.endDate && filterData.clinics) {
			setIsSearching(true);
			if (endPointConfig.endPoint) {
				let response = await callReportEndpoint(endPointConfig, filterData, endPointConfig.filterDataConfig);

				if (response.status == 200) {
					setReportData({ report_data: response.data });
					setIsSearching(false);
				} else {
					console.error(response);
					setIsSearching(false);
				}
			} else if (endPointConfig.displayMultipleReports) {
				let endPointResultData = {};
				await Promise.all(
					endPointConfig.endPoints.map(async (endPoint) => {
						let endPointReqRes = await callReportEndpoint(endPoint, filterData, endPointConfig.filterDataConfig);
						if (endPointReqRes.status == 200) {
							let endPointData = endPointReqRes.data;
							if (endPointData) {
								endPointResultData[endPoint.name + "_data"] = endPointData;
							}
						}
					})
				);
				setReportData(endPointResultData);
				setIsSearching(false);
			} else if (endPointConfig.endPoints) {
				let endPointResultData = {};
				await Promise.all(
					endPointConfig.endPoints.map(async (endPoint) => {
						let endPointReqRes = await callReportEndpoint(endPoint, filterData, endPointConfig.filterDataConfig);
						if (endPointReqRes.status == 200) {
							let endPointData = endPointReqRes.data;
							if (endPointData) {
								endPointResultData[endPoint.name + "Data"] = endPointData;
							}
						}
					})
				);

				const mergedMap = new Map();

				Object.entries(endPointResultData).forEach(([listName, list]) => {
					list.forEach((item) => {
						// Create a unique key based on item data:
						//let mergeKey = item.clinicKey;
						let mergeKey = `CLINIC-${item.territory}-${item.regionName}-${item.clinicName}-${item.source}-${item.rcCode}`;
						if (item.clinicKey === null || item.clinicKey === 0) {
							if (item.source.includes("REGIONAL AGGREGATE")) {
								mergeKey = `REGION-${item.territory}-${item.regionName}`;
							} else if (item.source.includes("TERRITORY AGGREGATE")) {
								mergeKey = `TERRITORY-${item.territory}`;
							} else if (item.source.includes("TOTAL AGGREGATE")) {
								mergeKey = `TOTAL`;
							}
						}

						const newItem = Object.entries(item).reduce((acc, [key, value]) => {
							if (endPointConfig.commonAccessors && endPointConfig.commonAccessors.includes(key)) {
								acc[key] = value; // Common fields retain their names
							} else {
								acc[`${listName}_${key}`] = value; // Prefix listName to unique fields
							}
							return acc;
						}, {});

						// Merge or set the new item:
						if (mergedMap.has(mergeKey)) {
							mergedMap.set(mergeKey, {
								...mergedMap.get(mergeKey),
								...newItem,
							});
						} else {
							mergedMap.set(mergeKey, { mergeKey: mergeKey, ...newItem });
						}
					});
				});

				const mergedArray = Array.from(mergedMap.values());

				const structuredData = [];
				let totalAggregate = null; // Initialize Special Row

				// Helper to find or create territory and region structures
				const findOrCreateTerritory = (territoryName, aggregateData = {}) => {
					let findMe = `TERRITORY-${territoryName}`;

					let territory = structuredData.find((t) => t.mergeKey === findMe);
					if (!territory) {
						territory = { name: territoryName, mergeKey: findMe, subRows: [], ...aggregateData };
						structuredData.push(territory);
					} else {
						// Spread aggregate data if it wasn't initially created with it
						Object.assign(territory, aggregateData);
					}
					return territory;
				};

				const findOrCreateRegion = (territory, regionName, aggregateData = {}) => {
					let findMe = `REGION-${territory.name}-${regionName}`;

					let region = territory.subRows.find((r) => r.mergeKey === findMe);
					if (!region) {
						region = { name: regionName, mergeKey: findMe, subRows: [], ...aggregateData };
						territory.subRows.push(region);
					} else {
						// Spread aggregate data if it wasn't initially created with it
						Object.assign(region, aggregateData);
					}
					return region;
				};

				// Populate the hierarchical structure
				mergedArray.forEach((item) => {
					//KEEPING THIS COMMENTED COMMENT BECUASE IT'S MORE COMPLICATED
					// if (item.territory === "N/A" || item.territory === "OTHER") {
					// 	console.log({
					// 		source: item.source,
					// 		clinicKey: item.clinicKey,
					// 		mergeKey: item.mergeKey,
					// 		clinicName: item.clinicName,
					// 		regionName: item.regionName,
					// 		territory: item.territory,
					// 	});
					// }
					if (item.source.includes("TOTAL AGGREGATE")) {
						totalAggregate = item;
					} else if (item.source.includes("TERRITORY AGGREGATE")) {
						findOrCreateTerritory(item.territory, item);
					} else if (item.source.includes("REGIONAL AGGREGATE")) {
						const territory = findOrCreateTerritory(item.territory);
						findOrCreateRegion(territory, item.regionName, item);
					} else {
						// Normal clinic data
						const territory = findOrCreateTerritory(item.territory);
						const region = findOrCreateRegion(territory, item.regionName);
						region.subRows.push(item);
					}
				});

				// Optionally, sort the territories and regions
				structuredData.sort((a, b) => a.name.localeCompare(b.name));
				structuredData.forEach((territory) => {
					territory.subRows.sort((a, b) => a.name.localeCompare(b.name));
				});

				setReportData({ report_data: structuredData });
				setTotalAggregateRow(totalAggregate);

				//const aggregatedStructuredData = aggregateData(structuredData);

				//setReportData(aggregatedStructuredData);

				setIsSearching(false);
			} else {
				setErrorFound(true);
				setIsSearching(false);
			}
		}
	};

	useEffect(() => {
		if (reportData && reportTableColumn && reportDetails) {
			let endPointConfig = JSON.parse(reportDetails.endPointConfig);
			let config = {};
			if (endPointConfig.displayMultipleReports) {
				endPointConfig.endPoints.map((item) => {
					const key = item.name;
					config[key + "_config"] = item;
				});
			} else {
				config = { report_config: endPointConfig };
			}
			const combinedReport = Object.keys(reportData).map((key) => {
				const reportKey = key.replace("_data", ""); // Remove 'Data' to match the column key
				return {
					data: reportData[key],
					column: reportTableColumn[`${reportKey}_column`],
					title: config[`${reportKey}_config`].title,
					fileName: sanitizeFilename(
						config[`${reportKey}_config`].fileName ? config[`${reportKey}_config`].fileName : config[`${reportKey}_config`].title ? config[`${reportKey}_config`].title : reportDetails.name
					),
				};
			});
			setReports(combinedReport);
		}
	}, [reportData, reportTableColumn, reportDetails]);

	if (reportName.toLowerCase() === "powerbi") {
		return (
			<>
				<iframe
					title="Amplifi Power BI Sample"
					src="https://app.powerbi.com/reportEmbed?reportId=b799c4d4-bf98-429d-8ad3-a391228ed606&autoAuth=true&ctid=f1ed0701-294e-404c-a13b-f81f707f845b"
					frameborder="0"
					allowFullScreen="true"
					style={{ width: "100%", height: "calc(100vh - 150px)" }}></iframe>
			</>
		);
	} else {
		return (
			<>
				{isSearching && <Loader message="Loading..." />}
				{noReportFound ? (
					<h2 className="text-center">No Report Found</h2>
				) : errorFound ? (
					<h2 className="text-center">Error Found in Report Config</h2>
				) : (
					reportDetails && (
						<div className="px-2" style={JSON.parse(reportDetails.endPointConfig).displayMultipleReports ? {} : { height: `calc(100vh - 155px)` }}>
							<div>
								<h5 className="text-capitalize">{reportDetails.name}</h5>
							</div>
							{reports &&
								reports.map((report, i) => {
									return (
										<React.Fragment key={i}>
											{report.title && <h6 className="text-capitalize fw-bold">{report.title}</h6>}
											<Table
												columns={report.column}
												data={report.data}
												totalAggregateRow={totalAggregateRow}
												reportTitle={report.title}
												fileName={report.fileName}
												allowPagination={allowPaginationOnTable}
												manualSortBy
											/>
										</React.Fragment>
									);
								})}
						</div>
					)
				)}
			</>
		);
	}
};

export default Report;
