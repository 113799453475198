import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import "../custom.css";

const DoubleDateDropdown = ({ startDate, endDate, setStartDate, setEndDate, setPredefinedRange }) => {
    const location = useLocation();
    const pathname = location.pathname.toLowerCase();

    const [showWorkshopDates, setShowWorkshopDates] = useState(false);

    const isKpiManagement = pathname === "/reports/kpimanagement";

    const onChange = isKpiManagement
        ? (dates) => {

            const [start, end] = dates;
        
            
        
            // Convert start and end to moment objects and set them to the start/end of their respective months
            const startOfStartMonth = moment(start).startOf("month");
            const endOfEndMonth = (end ? moment(end).endOf("month") : null);
            const today = moment();
        
            // Check if the start month is the current month
            if (startOfStartMonth.isSame(today, 'month')) {
                // If the selected month is the current month, set range to Month-to-Date
        
                const startDate = startOfStartMonth.toDate();
        
                setStartDate(startDate, "Start");
                setEndDate(today.subtract(1, "day").toDate(), "End");
            } else if (endOfEndMonth && startOfStartMonth.isSame(endOfEndMonth, 'month')) {
                // If only one full month is selected, and it's not the current month
        
                const startDate = startOfStartMonth.toDate();
                const endDate = endOfEndMonth.toDate();
        
                setStartDate(startDate, "Start");
                setEndDate(endDate, "End");
            } else if (endOfEndMonth && endOfEndMonth.isSame(today, 'month')) {
                // If multiple full months are selected and the end month is the current month
        
                const startDate = startOfStartMonth.toDate();
                const endDate = today.subtract(1, "day").toDate(); // Set end date to yesterday
        
                setStartDate(startDate, "Start");
                setEndDate(endDate, "End");
            } else {
                // If multiple full months are selected and the end month is not the current month
                setStartDate(startOfStartMonth.toDate(), "Start");
                setEndDate((endOfEndMonth ? endOfEndMonth.toDate() : endOfEndMonth), "End");
            }
        }
        : (dates) => {
            const [start, end] = dates;
            setStartDate(start, "Start");
            setEndDate(end, "End");
        };

    // Function to disable future months
    const filterDate = (date) => {
        // This will only allow months up to and including the current month
        return moment(date).isSameOrBefore(moment(), 'month');
    };

    const isFutureMonth = (date) => {
        return moment(date).isAfter(moment(), 'month');
    };

    const monthClassName = (date) => {
        
        return isFutureMonth(date) ? "future-month" : undefined;
    };

    return (
        <div className="datepicker-dropdown" style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#fff', position: 'absolute', zIndex: 1000, display: 'flex' }}>
            {pathname === "/reports/kpimanagement" ? (
                <>
                    <div style={{ width:195 }}>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            inline
                            filterDate={filterDate}
                            monthClassName={monthClassName}
                        />
                    </div>
                    <div style={{ flex: 1, paddingLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                            <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>Select Range</div>
                            <Dropdown.Item onClick={() => setPredefinedRange("Fiscal Year")}>Fiscal Year</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Month to Date")}>Month to Date</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Quarter to Date")}>Quarter to Date</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Year to Date")}>Year to Date</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Fiscal Year to Date")}>Fiscal Year to Date</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Last Month")}>Last Month</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Last Quarter")}>Last Quarter</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Last Year")}>Last Year</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Last Fiscal Year")}>Last Fiscal Year</Dropdown.Item>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div style={{ flex: 2 }}>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                            monthsShown={2}
                            dayClassName={(date) =>
                                showWorkshopDates ? "calendarWorkshop" : undefined
                            }
                        />
                    </div>
                    <div style={{ flex: 1, paddingLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>Select Range</div>
                            <Dropdown.Item onClick={() => setPredefinedRange("Today")}>Today</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Fiscal Year")}>Fiscal Year</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Month to Date")}>Month to Date</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Quarter to Date")}>Quarter to Date</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Year to Date")}>Year to Date</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Fiscal Year to Date")}>Fiscal Year to Date</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Last Month")}>Last Month</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Last Quarter")}>Last Quarter</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Last Year")}>Last Year</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPredefinedRange("Last Fiscal Year")}>Last Fiscal Year</Dropdown.Item>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

DoubleDateDropdown.propTypes = {
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired,
    setPredefinedRange: PropTypes.func.isRequired,
};

export default DoubleDateDropdown;
