import React, { useEffect, useState, useCallback } from "react";
import Table from "../Components/common/Table";
import Loader from "../Components/loader/Loader";
import moment from "moment";
import { Row, Col, Button, Offcanvas, Form, Card, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { apiRequest } from "../Components/api/Request";
import { amountFormat, percentageFormat, booleanFormat, linkFormat, dateFormat, buttonFormat } from "../Components/common/Helper";
import { getSessionData } from "../Components/security/Helper";
import securityCheck from "../Components/security/SecurityCheck";
import sanitizeFilename from "../Functions/sanitizeFilename";
import UserRolesEditor from "../Components/userRolesEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../custom.css";

const Maintenance = () => {
	const [indexDetails, setIndexDetails] = useState(null);
	const [indexTableColumn, setIndexTableColumn] = useState(null);
	const [indexEditConfig, setIndexEditConfig] = useState(null);
	const [offCanvasLayout, setOffCanvasLayout] = useState(null);
	const [newObjectFormat, setNewObjectFormat] = useState(null);
	const [indexConfig, setIndexConfig] = useState(null);
	const [indexData, setIndexData] = useState(null);
	const [index, setIndex] = useState(null);
	const [isSearching, setIsSearching] = useState(false);
	const [noMaintenanceIndexFound, setNoMaintenanceIndexFound] = useState(false);
	const [errorFound, setErrorFound] = useState(false);
	const [offCanvasAction, setOffCanvasAction] = useState("");
	const [offCanvasError, setOffcanvasError] = useState("");
	const [offCanvasErrorType, setOffcanvasErrorType] = useState("warning");
	const { indexName } = useParams();
	const [allowPaginationOnTable, setAllowPaginationOnTable] = useState("yes");

	const [currentPage, setCurrentPage] = useState(0);
	const [columnFilters, setColumnFilters] = useState([]);

	const [showOffcanvas, setShowOffcanvas] = useState(false);
	const [editData, setEditData] = useState({});

	const openEditForm = (rowData) => {
		

		let rowDataCopy = { ...rowData }; // Shallow copy of rowData

		if (rowDataCopy.geoEntitiesPerRole && typeof rowDataCopy.geoEntitiesPerRole === "string") {
			rowDataCopy.geoEntitiesPerRole = JSON.parse(rowDataCopy.geoEntitiesPerRole);
		}
		if(rowDataCopy.geoEntitiesPerRole === null){
			rowDataCopy.geoEntitiesPerRole = [];
		}
		
		setEditData(rowDataCopy);
		setShowOffcanvas(true);
	};

	useEffect(() => {
		
	}, [editData]);

	useEffect(() => {
		getMaintenanceDetails();
	}, []);

	useEffect(() => {
		if (indexDetails) {
			getIndexData();
		}
	}, [indexDetails]);

	let getMaintenanceDetails = async () => {
		setIsSearching(true);
		let response = await apiRequest(process.env.REACT_APP_API_URL, `Maintenance?urlName=${indexName}`, "get");

		

		if (response.status === 200) {
			setIndexDetails(response.data);

			let parsedConfigData = JSON.parse(response.data.config);
			

			let tableColumns = parsedConfigData.tableColumns;
			

			const updatedTableColums = columnMap(tableColumns);
			

			const editConfig = parsedConfigData.editConfig;
			

			const indexInfo = parsedConfigData.indexConfig[0];
			

			const offCanvasLayoutConfig = parsedConfigData.offCanvasLayout;
			

			const newObjectFormatConfig = parsedConfigData.newObjectFormat;
			

			setIndexEditConfig(editConfig);
			setIndexConfig(indexInfo);
			setOffCanvasLayout(offCanvasLayoutConfig);
			setNewObjectFormat(newObjectFormatConfig);

			setIndexTableColumn({ index_column: updatedTableColums });

			setAllowPaginationOnTable(parsedConfigData.allowPagination ? parsedConfigData.allowPagination : "yes");
		} else if (response.status === 204) {
			setNoMaintenanceIndexFound(true);
			setIsSearching(false);
		} else {
			console.error(response);
			setIsSearching(false);
		}
	};

	let columnMap = (columns) => {
		return columns.map((column) => {
			let newColumn = column;
			
			// Apply predefined formatter cells directly if specified
			if (newColumn.amountFormattor) {
				newColumn = { ...newColumn, Cell: amountFormattorCell }; // Use the existing function directly
			} else if (newColumn.percentageFormattor) {
				newColumn = { ...newColumn, Cell: percentageFormattorCell }; // Use the existing function directly
			} else if (newColumn.booleanFormattor) {
				newColumn = { ...newColumn, Cell: booleanFormattorCell };
			} else if (newColumn.linkFormattor) {
				newColumn = { ...newColumn, Cell: linkFormattorCell };
			} else if (newColumn.dateFormattor) {
				newColumn = { ...newColumn, Cell: dateFormattorCell };
			} else if (newColumn.buttonFormattor) {
				newColumn = { ...newColumn, Cell: buttonFormattorCell };
			}

			// Handling hidden columns
			if (newColumn.hiddenColumn) {
				newColumn.show = false; // Mark column as hidden but still processable
			}

			// Preserve original cell rendering
			const originalCell = newColumn.Cell || ((props) => props.value);

			// Enhance the Cell to handle conditional rendering based on row type
			newColumn.Cell = (props) => {
				

				const { row } = props;
				const rowType = row.values.source; // Assumes 'type' is available in row data
				
				const rowDepth = row.depth;
				
				// Determine if certain columns should be hidden based on the row type

				// Apply conditional visibility rules
				if (newColumn.show === false) {
					return null; // Skip rendering but keep processing
				}

				if (
					(rowType === "TERRITORY AGGREGATE" && ["regionName", "clinicName", "source", "rcCode"].includes(column.accessor)) ||
					(rowType === "REGIONAL AGGREGATE" && ["regionName", "clinicName", "source", "rcCode"].includes(column.accessor)) ||
					(rowType === "TOTAL AGGREGATE" && ["territory", "regionName", "clinicName", "source", "rcCode"].includes(column.accessor)) ||
					(!["TERRITORY AGGREGATE", "REGIONAL AGGREGATE"].includes(rowType) && ["territory", "regionName"].includes(column.accessor))
				) {
					return null; // Do not render the column for these row types
				}

				// Render expansion icon with FontAwesome if applicable
				if (column.canExpand) {
					return (
						<div style={{ cursor: "pointer", marginLeft: rowDepth * 20 }}>
							<span {...row.getToggleRowExpandedProps()}>
								<Row>
									<Col md={2}>
										<FontAwesomeIcon icon="triangle" rotation={row.isExpanded ? 180 : 90} size="2xs" />
									</Col>
									<Col md={10} style={{ textWrap: "nowrap" }}>
										{row.original.name}
									</Col>
								</Row>
							</span>
						</div>
					);
				}

				// Default rendering for non-special cases
				return originalCell(props);
			};

			// Recursively apply this mapping to any nested columns
			if (column.columns) {
				newColumn.columns = columnMap(column.columns);
			}

			return newColumn;
		});
	};

	let amountFormattorCell = (props) => {
		let val = amountFormat(props.value, props.column.amountFormattor);
		return val;
	};

	let percentageFormattorCell = (props) => {
		let val = percentageFormat(props.value, props.column.percentageFormattor);
		return val;
	};

	let booleanFormattorCell = (props) => {
		let val = booleanFormat(props.value, props.column.booleanFormattor);
		return val;
	};

	let linkFormattorCell = (props) => {
		
		let val = linkFormat(props.value, props.row.values.source, props.column.linkFormattor);
		return val;
	};

	let dateFormattorCell = (props) => {
		let val = dateFormat(props.value, props.column.dateFormattor);
		return val;
	};

	const buttonFormattorCell = ({ value, row, column }) => {
		// Only define the click handler inline if necessary, otherwise pass directly
		const handleClick = () => {
			openEditForm(row.original); // Assuming row.original contains the full data for the row
			setOffCanvasAction("Edit ");
		};

		return buttonFormat(value, column.buttonFormattor, handleClick);
	};

	const openNewObjectOnForm = (format) => {
		

		let newObject = {};

		format.forEach((field) => {
			let defaultValue;
			switch (field.fieldType) {
				case "text":
					defaultValue = ""; // Default for text
					break;
				case "number":
					defaultValue = 0; // Default for number
					break;
				case "bool":
					defaultValue = false; // Default for checkboxes
					break;
				case "array":
					defaultValue = []; //Default for arrays
					break;
				default:
					defaultValue = null; // Default for any unspecified types
			}
			newObject[field.fieldName] = field.defaultValue ?? defaultValue;
		});

		

		setOffCanvasAction("Add ");

		openEditForm(newObject);
	};

	const isEmpty = (value) => {
		
		return value === null || value === undefined || value === "";
	};

	let callIndexEndpoint = async (endPointConfig, filterData, filterDataConfig) => {
		let data = {};

		if (filterDataConfig) {
			filterDataConfig.foreach((config) => {
				let value = config.value ? config.value : config.accessor ? filterData[config.accessor] : "";
				data[config.name] = value;
			});
		} else {
			data = filterData;
		}

		let reqRes = await apiRequest(process.env.REACT_APP_API_URL, endPointConfig.endPoint, endPointConfig.type ? endPointConfig.type : "get", data);
		return reqRes;
	};

	let getIndexData = async () => {
		let endPointConfig = JSON.parse(indexDetails.endPointConfig);

		setIsSearching(true);
		if (endPointConfig.endPoint) {
			let response = await callIndexEndpoint(endPointConfig);

			if (response.status === 200) {
				setIndexData({ index_data: response.data });
				setIsSearching(false);
			} else {
				console.error(response);
				setIsSearching(false);
			}
		} else {
			setErrorFound(true);
			setIsSearching(false);
		}
	};

	useEffect(() => {
		if (indexData && indexTableColumn && indexDetails) {
			let endPointConfig = JSON.parse(indexDetails.endPointConfig);
			let config = { index_config: endPointConfig };

			const combinedReport = Object.keys(indexData).map((key) => {
				const reportKey = key.replace("_data", ""); // Remove 'Data' to match the column key
				return {
					data: indexData[key],
					column: indexTableColumn[`${reportKey}_column`],
					title: config[`${reportKey}_config`].title,
					fileName: sanitizeFilename(
						config[`${reportKey}_config`].fileName ? config[`${reportKey}_config`].fileName : config[`${reportKey}_config`].title ? config[`${reportKey}_config`].title : indexDetails.name
					),
				};
			});
			setIndex(combinedReport);
		}
	}, [indexData, indexTableColumn, indexDetails]);

	const updateGeoEntitiesPerRole = (updatedRoles) => {
		setEditData((prevData) => ({
			...prevData,
			geoEntitiesPerRole: updatedRoles,
		}));
	};

	const submitEditedObject = async () => {
		try {
			//setIsSearching(false);
			

			let unfilledRequiredFields = false;

			// Check to make sure that any required fields are filled in
			if (newObjectFormat) {
				newObjectFormat.forEach((field) => {
					if (field.required && isEmpty(editData[field.fieldName])) {
						unfilledRequiredFields = true;
					}
				});
			}

			if (unfilledRequiredFields) {
				setOffcanvasError("Please fill in all required fields");
				setOffcanvasErrorType("danger");
				return; // Early return to stop execution if there are unfilled fields
			}

			

			let userData = getSessionData();
			editData.editingUserId = userData.userId; //currentUserId;


			// Constructing data for submission
			let data = {
				...editData,
				geoEntitiesPerRole: editData.geoEntitiesPerRole.map((role) => ({
					...role,
					isDefaultRole: role.isDefaultRole === true, // Convert numeric to boolean
				})),
			};

			

			let saveResponse = await apiRequest(process.env.REACT_APP_API_URL, "Maintenance" + indexConfig.saveEndpoint, "POST", data);

			

			if (saveResponse.status === 200) {
				const responseData = await saveResponse.data; // Ensure correct parsing of JSON response

				// Handle a successful response
				
				getMaintenanceDetails();
				setShowOffcanvas(false); // Close the offcanvas on success
			} else {
				const responseData = await saveResponse.data; // Parsing response even in case of error
				console.error("Failed to save:", responseData);
				// Optionally update the UI here to reflect the error
			}
		} catch (error) {
			// Handle network errors or other unexpected errors
			console.error("Error saving changes:", error);
			// Optionally update the UI here to reflect the error
		}
	};

	const handleCloseOffCanvas = () => {
		setShowOffcanvas(false);
		setOffCanvasAction("");
		setOffcanvasError("");
	};

	const updateColumnFilters = (id, value) => {
		setColumnFilters((prevFilters) => {
			// Check if filter with the same id already exists
			const existingFilterIndex = prevFilters.findIndex((filter) => filter.id === id);

			// Create a new array based on previous filters
			let newFilters;

			if (existingFilterIndex >= 0) {
				// If value is empty, remove the filter
				if (value === "" || value === null || value === undefined) {
					newFilters = prevFilters.filter((filter) => filter.id !== id);
				} else {
					// Update existing filter's value
					newFilters = prevFilters.map((filter, index) => (index === existingFilterIndex ? { id, value } : filter));
				}
			} else {
				// Add new filter if value is not empty
				if (value !== "" && value !== null && value !== undefined) {
					newFilters = [...prevFilters, { id, value }];
				} else {
					// If value is empty and filter doesn't exist, keep previous filters
					newFilters = prevFilters;
				}
			}

			
			return newFilters;
		});
	};

	

	return (
		<>
			{isSearching && <Loader message="Loading..." />}
			{noMaintenanceIndexFound ? (
				<h2 className="text-center">No Index Found</h2>
			) : errorFound ? (
				<h2 className="text-center">Error Found in Index Config</h2>
			) : (
				indexDetails && (
					<div className="px-2" style={{ height: `calc(100vh - 155px)` }}>
						<div>
							<Row>
								<Col xs={{ span: 6 }}>
									<h4 className="text-capitalize mb-0">{indexDetails.name}</h4>
								</Col>
								<Col xs={{ span: 6 }}>
									{newObjectFormat ? (
										<Button variant="outline-primary" className="float-end" onClick={() => openNewObjectOnForm(newObjectFormat)}>
											<FontAwesomeIcon icon="plus" />
											{" Add"}
										</Button>
									) : (
										""
									)}
								</Col>
							</Row>
						</div>
						{index &&
							index.map((maint, i) => {
								return (
									<React.Fragment key={i}>
										{maint.title && <h6 className="text-capitalize fw-bold">{maint.title}</h6>}
										<Table
											pageIndexValue={currentPage}
											updatePageIndexValue={setCurrentPage}
											columnFilter={columnFilters}
											updateColumnFilter={updateColumnFilters}
											columns={maint.column}
											data={maint.data}
											reportTitle={maint.title}
											fileName={index[0].fileName}
											allowPagination={allowPaginationOnTable}
											manualSortBy
										/>
									</React.Fragment>
								);
							})}
					</div>
				)
			)}
			{indexConfig ? (
				<Offcanvas
					show={showOffcanvas}
					className="offcanvas-scroll"
					backdrop="static"
					onHide={() => handleCloseOffCanvas()}
					placement="end"
					style={{ zIndex: 1400, width: indexConfig.offCanvasWidth, top: "73px" }}>
					<Offcanvas.Header closeButton style={{ paddingRight: "66px" }}>
						<Row className="w-100 ms-3">
							<Col className="ps-0">
								<Offcanvas.Title>{offCanvasAction + indexConfig.singularObject}</Offcanvas.Title>
							</Col>
						</Row>
					</Offcanvas.Header>
					<Offcanvas.Body className="pt-0">
						<div className="offcanvas-content">
							{offCanvasError !== "" && <Alert variant={offCanvasErrorType}>{offCanvasError}</Alert>}
							{offCanvasLayout &&
								offCanvasLayout.map((layout) => {
									switch (layout.componentLayout) {
										case "useEditConfig":
											return (
												<Row className="w-100" key={"offcanvas_" + layout.componentLayout}>
													<Col>
														<Card className="p-0 m-3">
															<Card.Header>
																<h5>{indexConfig.singularObject} Info</h5>
															</Card.Header>
															<Card.Body>
																<Row>
																	{indexEditConfig &&
																		indexEditConfig.map((field) => {
																			switch (field.fieldType) {
																				case "checkbox":
																					
																					return (
																						<Col xs={{ span: field.span }} key={field.parameter}>
																							<Form.Group className="mb-3 float-start">
																								<Form.Check // prettier-ignore
																									type={"checkbox"}
																									label={<span>{field.label}</span>}
																									className="text-center mt-4 pt-2"
																									name={field.parameter}
																									id={field.parameter}
																									onChange={(e) =>
																										setEditData((prev) => ({
																											...prev,
																											[field.parameter]: e.target.checked,
																										}))
																									}
																									checked={editData[field.parameter] ?? false}
																								/>
																							</Form.Group>
																						</Col>
																					);
																				case "freeText":
																					return (
																						<Col xs={{ span: field.span }} key={field.parameter}>
																							<Form.Group className="mb-3">
																								<Form.Label>{field.label}</Form.Label>
																								<Form.Control
																									type="text"
																									name={field.parameter}
																									onChange={(e) =>
																										setEditData((prev) => ({
																											...prev,
																											[field.parameter]: e.target.value,
																										}))
																									}
																									required={field.required}
																									value={editData[field.parameter] || ""}
																									className={
																										field.required && field.required === true && isEmpty(editData[field.parameter])
																											? "unfilledRequired"
																											: ""
																									}
																								/>
																							</Form.Group>
																						</Col>
																					);
																				default:
																					return null; // Render nothing for unrecognized types
																			}
																		})}
																</Row>
															</Card.Body>
														</Card>
													</Col>
												</Row>
											);
										case "userRoles":
											return (
												<Row className="w-100" key={"offcanvas_userRoles"}>
													<UserRolesEditor userRoles={editData.geoEntitiesPerRole} updateRoles={updateGeoEntitiesPerRole} />
												</Row>
											);
										default:
											return <></>;
									}
								})}
						</div>

						{indexEditConfig && (
							<div className="offcanvas-footer" style={{ paddingRight: "65px" }}>
								<Row>
									<Col>
										<div className="text-end">
											<Button variant={"danger"} className="me-2" onClick={() => handleCloseOffCanvas()}>
												<FontAwesomeIcon icon={"xmark"} size="lg" /> {"Cancel"}
											</Button>
											<Button variant={"primary"} onClick={submitEditedObject}>
												<FontAwesomeIcon icon={"floppy-disk"} /> {"Save"}
											</Button>
										</div>
									</Col>
								</Row>
							</div>
						)}
					</Offcanvas.Body>
				</Offcanvas>
			) : (
				<></>
			)}
		</>
	);
};

export default Maintenance;
