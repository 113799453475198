import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../custom.css";

const AuthenticatedUserDropdown = () => {
  const handleLogout = () => {
    window.open("/LogOut", "_self");
  };

  return (
    <>
      <DropdownButton
        id="dropdown-basic-button-user"
        variant="no-outline-secondary"
        title={<FontAwesomeIcon icon="user-circle" size="2xl" />}
        style={{
          paddingTop: "5px",
          zIndex: 1050,
          height: "45px",
          border: "none",
        }}
        align="end"
      >
        <Dropdown.Item onClick={handleLogout}>
          <FontAwesomeIcon icon="arrow-right-from-bracket" /> Log Out
        </Dropdown.Item>
      </DropdownButton>
    </>
  );
};

export default AuthenticatedUserDropdown;
